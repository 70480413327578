<template>
  <menu
    class="header__nav-menu"
    :class="{
      'is-login': $auth.loggedIn
    }"
  >
    <ul class="header__nav-menu__list">
      <li class="header__nav-menu__list__item">
        <a
          class="label header__nav-menu__list__item__link"
          exact
          data-cy="header-all-service"
          @mouseenter="changeDropdownMenuStatusHandler(true)"
          @mouseleave="changeDropdownMenuStatusHandler(false)"
        >全部服務
          <i
            class="icon-chevron-down arrow"
            :class="{
              'is-open': showDropdownMenu
            }"
          />
          <transition name="header-dropdown">
            <NavDropDownMenu
              v-show="showDropdownMenu"
              :dropdown-menu-list="allService"
              :service-is-loading="serviceIsLoading"
              @changeDropdownMenuStatusHandler="changeDropdownMenuStatusHandler"
            />
          </transition>
        </a>
      </li>
      <li class="header__nav-menu__list__item">
        <nuxt-link
          :to="{ name: 'index-id', params: { id: 157, toTop: true } }"
          class="label header__nav-menu__list__item__link"
          data-cy="header-appliance-cleaning"
          @click.native="logHeaderGaEvent('家電清洗')"
        >
          家電清洗
        </nuxt-link>
      </li>
      <li class="header__nav-menu__list__item">
        <nuxt-link
          class="label header__nav-menu__list__item__link"
          :to="{ name: 'informationStation' }"
          data-cy="header-information-station"
          @click.native="logHeaderGaEvent('生活情報站')"
        >
          生活情報站
        </nuxt-link>
      </li>
      <li class="header__nav-menu__list__item">
        <a
          class="label header__nav-menu__list__item__link"
          href="https://www.smartdaily.com.tw/household/"
          target="_blank"
          @click="logHeaderGaEvent('關於我們')"
        >
          關於我們
        </a>
      </li>
      <li class="flex-end member-setting header__nav-menu__list__item">
        <img
          v-if="showAlertIcon"
          class="alert-img"
          src="@/assets/image/edit-alert.svg"
        >
        <a
          ref="navMemberDropDownMenu"
          class="label header__nav-menu__list__item__link"
          data-cy="header-member-setting"
          @click="openMemberSettingDropdownHandler"
        >
          <v-badge
            :value="hasRedPoint && !showAlertIcon"
            color="pink"
            dot
            left
            offset-y="-2"
          >
            會員專區
            <i
              class="icon-chevron-down arrow"
              :class="{
                'is-open': showMemberDropdownMenu
              }"
            />
          </v-badge>
          <transition name="header-dropdown">
            <NavMemberDropDownMenu
              v-show="showMemberDropdownMenu"
              @logout="$emit('logout')"
            />
          </transition>
        </a>
      </li>
    </ul>
  </menu>
</template>

<script>
import NavDropDownMenu from '@/components/Nav/NavDropDownMenu'
import NavMemberDropDownMenu from '@/components/Nav/NavMemberDropdownMenu'
import { mapGetters } from 'vuex'
export default {
  components: {
    NavDropDownMenu,
    NavMemberDropDownMenu
  },
  props: {
    allService: {
      type: Array,
      default: () => []
    },
    serviceIsLoading: Boolean
  },
  data () {
    return {
      showDropdownMenu: false,
      showMemberDropdownMenu: false
    }
  },
  computed: {
    ...mapGetters(['isGuestMember', 'hasRedPoint']),
    showAlertIcon () {
      return this.isGuestMember || (this.$auth.loggedIn && this.$auth.user && !this.$auth.user.IsServiceAccountTransferEnd)
    }
  },
  watch: {
    showMemberDropdownMenu (val) {
      if (val) {
        document.querySelector('body').addEventListener('click', this.clickOutsideHandler)
      } else {
        document.querySelector('body').removeEventListener('click', this.clickOutsideHandler)
      }
    }
  },
  methods: {
    changeDropdownMenuStatusHandler (status) {
      this.showDropdownMenu = status
      if (status) this.logHeaderGaEvent('全部服務')
    },
    openMemberSettingDropdownHandler () {
      this.showMemberDropdownMenu = !this.showMemberDropdownMenu
    },
    logHeaderGaEvent (headerName) {
      this.$gaLogEvent('導覽列', `header_${headerName}`)
    },
    clickOutsideHandler (e) {
      if (this.isMobile) {
        this.menuMobileIsOpen = false
      } else {
        const dropdownMenu = this.$refs.navMemberDropDownMenu
        const clickDropdownMenu = dropdownMenu === e.target || dropdownMenu.contains(e.target)
        if (!clickDropdownMenu && this.showMemberDropdownMenu) {
          this.showMemberDropdownMenu = false
        }
      }
    }
  }
}
</script>

<style lang="scss">
.header__nav-menu {
  height: $headerHeight;
  &__list {
    list-style: none;
    display: flex;
    vertical-align: baseline;
    font-size: 0;
    width: 100%;
    &__item {
      position: relative;
      display: inline-block;
      &__link {
        display: inline-flex;
        align-items: center;
        height: $headerHeight;
        padding-inline: 16px;
        box-sizing: border-box;
        font-size: pxTorem(17px);
        line-height: pxTorem(24px);
        font-weight: bold;
        text-decoration: none;
        transition: background-color .3s, color .3s;
        user-select: none;
        color: $fontColor;
        text-decoration: none;
        word-break: keep-all;
        .v-badge {
          display: inline-flex;
          align-items: center;
        }
        .arrow {
          margin-left: 5px;
          display: inline-block;
          transition: transform .5s;
          font-size: pxTorem(20px);
          &.is-open {
            transform: rotate(180deg);
          }
        }
      }
      &.flex-end {
        margin-left: auto;
      }
      &.member-setting {
        position: relative;
        .alert-img {
          position: absolute;
          top: 15px;
          left: 0px;
        }
      }
    }
  }
}
.header-dropdown-leave-active,
.header-dropdown-enter-active {
  transition: all 0.3s ease;
  transform: translate(0, 8px);
}
.header-dropdown-enter-to,
.header-dropdown-leave-from {
  transform: translate(0, 0px);
  opacity: 1;
  visibility: visible;
}
.header-dropdown-leave-to {
  opacity: 0;
  visibility: hidden;
}
/** 手機 */
@media screen and (max-width: $mobileWidth) {
  .header__nav-menu {
    display: none;
  }
}
</style>
<style lang="scss" scoped>
.header__nav-menu__list__item__link {
  color: $fontColor;
  &:hover, &.is-open {
    color: $mainColor;
  }
}
</style>
